.shopifyThemeBackups {
  margin-top: 40px;
  border: 1px solid #eee;
  border-bottom: none;

  &__head {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #eee;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
      opacity: 0.8;
    }

    &__left {
      display: flex;
      gap: 12px;
      align-items: center;

      &--name {
        font-size: 24px;
      }

      &--deleted {
        padding: 2px 4px;
        color: #fff;
        background-color: #bbbbbb;
        font-size: 12px;
        font-weight: bold;
        border-radius: 4px;
      }
    }

    &__right {
      border: none;
      background-color: transparent;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &--closed {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;

    &--hidden {
      display: none;
    }
  }
}
